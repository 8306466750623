import { QueryIndustryListRes, queryDictDataByTypeParams } from '@/types/apiTypes';
import { request } from '@/utils/frameMethod';
import { jllRequest } from '@/utils/request';
import { IndustryList } from '../types';

/** 一级二级行业 */
export async function queryIndustryTree(): Promise<IndustryList> {
  const result = await request({
    url: '/industry/newindustry/queryIndustryTree',
    method: 'post',
  });
  return result.data;
}

// 查询该城市的产业分布信息
export function queryIndustryList() {
  return jllRequest<QueryIndustryListRes>({
    url: '/industry/queryOneTwoIndustryList',
    method: 'get',
  });
}

// 查询该城市的产业分布选择器Options
export async function queryIndustryOptions() {
  const res = await queryIndustryTree();

  return res.map((firstLevelItem) => {
    return {
      label: firstLevelItem.oneIndustry,
      value: firstLevelItem.oneIndustry,
      children: firstLevelItem.twoLevelIndustryList.map((secondLevelItem) => ({
        label: secondLevelItem.twoIndustry,
        value: secondLevelItem.twoIndustry,
      })),
    };
  });
}

export async function getMinMaxDate(cityName: string) {
  const result = await jllRequest({
    url: '/datatime/getCityDataListTime',
    method: 'post',
    data: {
      cityNames: [cityName],
      timeType: 2,
    },
  });

  return result;
}

// 获取数据字典
export async function queryDictDataByType(paramType: queryDictDataByTypeParams) {
  const res = await jllRequest({
    url: '/system/dict/data/queryDictDataByType',
    method: 'get',
    params: {
      paramType,
    },
  });
  const result = res?.map((item) => ({
    label: item.dictLabel,
    value: item.dictValue,
  }));

  return result;
}
