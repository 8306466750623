import { PropertyType } from '@/constants/enums';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { queryPropertyList, querySummaryAndAnalysis } from '../api';
import { BoundariesType, MapFormValues, MapMode, PopupFormValues } from '../types';
import {
  AnalysisItem,
  IndustrialPropertyItem,
  QueryIndustrialFormParams,
  Summary,
} from '../types/apiTypes';

const exceptKeys = ['propertyType', 'secondaryIndustries', 'districtAndSubMarket'];

const convertFormDataToQueryData = (formData: PopupFormValues) => {
  const queryData = {
    ...formData,
    // projectLevel: ['1', '2', '4'],
    // subMarkets: ['1', '2', '3', '4'],
    // secondaryIndustries: [],
    // primaryIndustries: [],
    // city: '成都市',
    // enterpriseSources: [],
    // queryTime: '2023-Q1',
    // industryProjectLevel: [],
  } as any as QueryIndustrialFormParams;

  Object.keys(queryData).forEach((keyName) => {
    if (exceptKeys.includes(keyName)) {
      Reflect.deleteProperty(queryData, keyName);
    }
  });

  if (formData.propertyType?.[PropertyType.commercial]) {
    queryData.projectLevel = formData.propertyType?.[PropertyType.commercial];
  }

  if (formData.propertyType?.[PropertyType.industrial]) {
    queryData.industryProjectLevel = formData.propertyType?.[PropertyType.industrial];
  }

  if (formData.districtAndSubMarket?.district) {
    queryData.adminRegions = formData.districtAndSubMarket?.district;
  }

  if (formData.districtAndSubMarket?.subMarket) {
    queryData.subMarkets = formData.districtAndSubMarket?.subMarket;
  }

  if (formData.secondaryIndustries) {
    queryData.secondaryIndustries = Object.values(formData.secondaryIndustries).flat(1);
    queryData.primaryIndustries = Object.entries(formData.secondaryIndustries)
      .filter(([_, value]) => value.length)
      .map(([key]) => key);
  }

  return queryData;
};

const useIndustrialStore = create(
  immer(
    combine(
      {
        industryCompetitivenessAnalysisList: [] as AnalysisItem[],
        propertyList: [] as IndustrialPropertyItem[],
        summary: {} as Summary | null,
        mapMode: 'districtAndMarketMode' as MapMode,
        boundariesType: 'district' as BoundariesType,
        subMarket: '' as string,
        subMarketId: '' as string,
        scaleToSubMarket: false,
        formData: {} as PopupFormValues,
        center: [] as number[] | null | undefined,
      },
      (set, get) => ({
        queryPageData: async (data: PopupFormValues, mapParams?: MapFormValues | undefined) => {
          const getter = get() as any;
          let params = data;

          if (mapParams) {
            params = { ...data, ...mapParams };
          }

          set({
            boundariesType: params.districtAndSubMarket?.district?.length
              ? 'district'
              : 'subMarket',
            formData: data,
          });

          await getter.querySummaryAndAnalysis(params);
          getter.queryPropertyList(params);
        },
        setQueryParam: (data: PopupFormValues) => {
          set({
            boundariesType: data.districtAndSubMarket?.district?.length ? 'district' : 'subMarket',
            formData: data,
          });
        },
        queryPropertyList: async (data: PopupFormValues) => {
          const propertyList = await queryPropertyList(convertFormDataToQueryData(data));
          set({ propertyList });
          return propertyList;
        },
        querySummaryAndAnalysis: async (data: PopupFormValues) => {
          const res = await querySummaryAndAnalysis(convertFormDataToQueryData(data));
          set({
            industryCompetitivenessAnalysisList: res.competitivenessAnalysis,
            summary: res,
          });
        },
        toggleMapMode: (mode: MapMode) => {
          set({ mapMode: mode });
        },
        setSubMarket: (name: string) => {
          set({ subMarket: name });
        },
        setSubMarketId: (id: string) => {
          set({ subMarketId: id });
        },
        setSubMarketScale: (status: boolean) => {
          set({ scaleToSubMarket: status });
        },
        setCenter: (center: number[] | null | undefined) => {
          set({ center });
        },
      })
    )
  )
);

export default useIndustrialStore;
