import ReactECharts from 'echarts-for-react';
import { useMemo, useState, useEffect } from 'react';
import Empty from '@/components/Empty/index.tsx';
import useTransactionAnalysisStore from '../../store';
import { itemColor } from '@/components/Charts/config';

export default () => {
  const [chartOptions, setChartOptions] = useState({});
  const { chartData } = useTransactionAnalysisStore((state: any) => ({
    chartData: state.chartData,
  }));

  const hasData = useMemo(() => {
    return chartData?.length > 0;
  }, [chartData]);

  const color = itemColor;

  const getColor = (idx = 0) => {
    const colorLen = color.length;
    return color[idx % colorLen];
  };

  const getOptions = () => {
    const options = {
      color,
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          const { name, color, data } = params;
          const { proportionChar } = data;
          return `<div>
            <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${color};"></span>
            <span style="display:inline-block;width:${
              name.length * 15
            }px">${name}</span>${proportionChar}</div>`;
        },
      },
      series: [
        {
          type: 'treemap',
          left: 'left',
          top: 'top',
          height: '100%',
          width: '100%',
          roam: false,
          nodeClick: false,
          breadcrumb: {
            show: false,
          },
          label: {
            show: true,
            formatter: (params: any) => {
              const { name, proportionChar } = params.data;
              return `${name}${proportionChar}`;
            },
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: [
            {
              itemStyle: {
                borderWidth: 0,
                gapWidth: 0,
              },
            },
          ],
          data: chartData?.map((item: any, index: number) => ({
            value: item.area,
            name: item.oneIndustry,
            proportionChar: item.proportionChar,
            itemStyle: {
              color: getColor(index),
            },
          })),
        },
      ],
    };
    setChartOptions(options);
  };

  useEffect(getOptions, [chartData]);

  if (!hasData) {
    return <Empty />;
  }
  return (
    <ReactECharts
      option={chartOptions}
      notMerge
      lazyUpdate
    />
  );
};
