import { Table } from 'jlld';
import useTransactionAnalysisStore from '../../store';
// import styles from './style.module.less';

export default () => {
  const { clinchData } = useTransactionAnalysisStore((state: any) => ({
    clinchData: state.clinchData,
  }));

  const columns = [
    {
      title: '成交季度',
      dataIndex: 'clinchQuarter',
      key: 'clinchQuarter',
    },
    {
      title: '企业名称',
      dataIndex: 'tenantCompanyName',
      key: 'tenantCompanyName',
    },
    {
      title: '面积(平方米)',
      dataIndex: 'area',
      key: 'area',
    },

    {
      title: '一级行业',
      dataIndex: 'oneIndustry',
      key: 'oneIndustry',
    },
    {
      title: '二级行业',
      dataIndex: 'twoIndustry',
      key: 'twoIndustry',
    },
    {
      title: '账面租金(元/㎡/月)',
      dataIndex: 'bookRent',
      key: 'bookRent',
    },
    {
      title: '免租期',
      dataIndex: 'freeRentPeriod',
      key: 'freeRentPeriod',
    },
    {
      title: '楼层位置',
      dataIndex: 'floorLocation',
      key: 'floorLocation',
    },
    {
      title: '成交类型',
      dataIndex: 'clinchType',
      key: 'clinchType',
    },
  ];

  const pagination = {
    current: 1,
    pageSize: 10,
    pageSizeOptions: [15, 50, 100],
    total: clinchData.total,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共 ${total} 条`,
  };

  return (
    <Table
      dataSource={clinchData.records}
      columns={columns}
      pagination={pagination}
    />
  );
};
