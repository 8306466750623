import styles from './style.module.less';
import classNames from 'classnames';
import useMarketPerformanceStore from '../../store';
import { RankMap } from '../../types';
import RentChart from './RentChart';
import useProjectDetailStore from '@/pages/Z-ProjectDetail/store';
import { formatField } from '@/utils/tool';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useUnitExchange } from '@/hooks/useUnitExchange';

const RentIntro = (props: { data: RankMap }) => {
  const { data } = props;
  const {
    data: { params },
  } = useMainFrameContext();

  const { generateRentValue, generateRentUnit } = useUnitExchange();

  const getRatio = () => {
    const ratio = String(data?.quarterRatio ?? '');
    const displayRadio = formatField(ratio, true);
    return {
      radio: +ratio.replace('%', ''),
      displayRadio:
        displayRadio.includes('--') || displayRadio.includes('%')
          ? displayRadio
          : `${displayRadio}%`,
    };
  };

  const getRadioArrow = () => {
    const { radio, displayRadio } = getRatio();
    if (+radio === 0) {
      return null;
    }
    return (
      <i className={classNames(styles.arrow, displayRadio?.includes('-') && styles.downArrow)}></i>
    );
  };

  return (
    <div className={styles.rentIntor}>
      <label className={styles.desc}>最近季度账面租金</label>
      <div className={styles.rent}>
        <span>
          {generateRentValue(formatField(data?.lastQuarterFaceRent), 'bookRentUnit', true)}
        </span>
        {generateRentUnit('bookRentUnit')}
      </div>
      <div className={styles.ratio}>
        环比上季度<span>{getRatio().displayRadio}</span>
        {data?.quarterRatio && data?.quarterRatio !== '0%' ? getRadioArrow() : null}
      </div>
      <label className={styles.desc}>该项目账面租金</label>
      <div className={styles.rank}>
        在周边3公里{params.type === 'industry' ? '产办' : '商办'}项目排名
        {formatField(data?.nearbyRank)}
      </div>
      <div className={styles.rank}>
        在所在子市场-{formatField(data?.projMarket)}项目排名第{formatField(data?.marketRank)}
      </div>
      <div className={styles.rank}>
        在{formatField(data?.projCity)}
        {params.type === 'industry' ? '产办' : '商办'}项目排名第{formatField(data?.cityRank)}
      </div>
    </div>
  );
};

export default () => {
  const projectDetail = useProjectDetailStore((state) => state.projectDetail);
  const rankData = useMarketPerformanceStore((state) => state.rankData);

  const { generateRentUnit } = useUnitExchange();

  return (
    <div className={classNames('marketPerformanceChart', styles.rentalPerformance)}>
      <h2 className="title">租金表现</h2>
      <div className={styles.content}>
        <RentIntro data={rankData.priceRank} />
        <RentChart
          className={styles.rentChart}
          legend={{
            projectPriceActionList: projectDetail?.baseInfo?.cnName,
            nearbyPriceActionList: '周边3公里项目租金',
            submarketPriceActionList: rankData?.priceRank?.projMarket,
            cityPriceActionList: rankData?.priceRank?.projCity,
          }}
          unit={generateRentUnit('bookRentUnit')}
          data={rankData.priceChart}
        />
      </div>
    </div>
  );
};
