import BlockTitle, { SwitchType } from '../BlockTitle';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import SelectedBarChart from '../SelectedBarChart';

const IndustryCompetitive = (props: { changeType?: () => void; switchType?: SwitchType }) => {
  const { scaleToSubMarket } = useIndustrialStore((state) => ({
    scaleToSubMarket: state.scaleToSubMarket,
  }));

  return (
    <div>
      <BlockTitle
        title="一级行业分布排名"
        showSwitch
        switchType={props.switchType!}
        onChangeType={props.changeType!}
        showTip={!scaleToSubMarket}
        tipText="此图表的数据统计不受行业筛选器的影响，行业选择只影响柱子的颜色，被选择的行业高亮显示"
      />
      <SelectedBarChart dataType={props.switchType!} />
    </div>
  );
};

export default IndustryCompetitive;
