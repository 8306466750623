export const heatMapConfig = {
  style: {
    strokeColor: '#FC888F',
    strokeWeight: 3,
    fillColor: '#fff',
    fillOpacity: 0,
  },
  disabled: true,
};

export const polygonKey = {
  districtAndMarketMode: 'districtAndMarketMode',
  heatMapMode: 'heatMapMode',
  areaMarksMode: 'heatMapMode',
};
