import { useMapContext } from '@uiw/react-amap-map';
import styles from './style.module.less';
import { Props } from './types';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import useCommonOptionsStore from '@/store/commonOptions';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import { industryCompareAnalysisItem } from '../../../types/apiTypes';
import { thousandSplitNumber } from '@/utils/tool';
import useMainFrameContext from '@/hooks/useMainFrameContext';

export default (props: Props) => {
  const { activeItem } = props;
  const { map } = useMapContext();
  const { data } = useMainFrameContext();

  const { summary, setSubMarket, setSubMarketId, formData, queryPageData, setSubMarketScale } =
    useIndustrialStore((state) => ({
      summary: state.summary,
      setSubMarket: state.setSubMarket,
      setSubMarketId: state.setSubMarketId,
      setSubMarketScale: state.setSubMarketScale,
      formData: state.formData,
      queryPageData: state.queryPageData,
    }));

  const {
    dateRange: [start, end],
  } = useCommonOptionsStore((state) => ({
    dateRange: state.dateRange,
  }));

  const { auth } = useReactShareAuthStore();
  const hasDetailAuth = (auth as any).buttonAuth?.some(
    (item: any) => item.button === 'front:submarket:overview'
  );

  const activeItemData: industryCompareAnalysisItem | undefined =
    summary?.industryCompareAnalysis?.find((item) => item.name === activeItem?.name);

  const onMarksButtonClick = () => {
    const districtAndSubMarket = activeItem?.adcode
      ? { district: [activeItem.name] }
      : { subMarket: [activeItem?.id] };
    queryPageData(formData, { districtAndSubMarket });
    map?.setZooms([8, 20]);
    map?.setZoomAndCenter(14, activeItem?.center, true, 0);
    setSubMarketScale(true);
    setSubMarket(activeItem?.id ? activeItem?.name : '');
    setSubMarketId(activeItem?.id || '');
  };

  const onDetailClick = () => {
    let queryTime = formData.queryTime;
    if (!queryTime.includes('Q')) {
      const [startYear] = start.split('-');
      const [endYear] = end.split('-');
      if (queryTime > startYear && queryTime < endYear) {
        queryTime = `${queryTime}-Q4`;
      } else if (queryTime >= endYear) {
        queryTime = end;
      } else {
        queryTime = start;
      }
    }
    localStorage.setItem('queryTime', queryTime);
    data.router.push(`/industrial-analysis/commercial-detail/${activeItem?.id}`);
  };

  const mainIndustryText = ['', '租户行业Top3', '新租行业Top3', '退租行业Top3'];

  return (
    <div className={styles.districtInfoWindow}>
      <div className={styles.districtName}>{activeItemData?.name}</div>
      <div className={styles.districtInfo}>
        <div className={styles.item}>
          <strong>{thousandSplitNumber(activeItemData?.tenantCount)}</strong>
          <span className={styles.discription}>租户数（家）</span>
        </div>
        <div className={styles.item}>
          <strong>{thousandSplitNumber(activeItemData?.totalLeasedArea)}</strong>
          <span className={styles.discription}>租户租赁总面积（㎡）</span>
        </div>
        <div className={styles.item}>
          <strong>{thousandSplitNumber(activeItemData?.businessPropertyCount)}</strong>
          <span className={styles.discription}>商业办公（个）</span>
        </div>
        <div className={styles.item}>
          <strong>{thousandSplitNumber(activeItemData?.industryPropertyCount)}</strong>
          <span className={styles.discription}>产业办公（个）</span>
        </div>
      </div>
      {activeItemData?.leadingIndustry?.length > 0 ? (
        <div className={styles.mainIndustry}>
          <span>{mainIndustryText[formData.leaseType]}</span>
          <span className={styles.leadingIndustry}>{activeItemData?.leadingIndustry}</span>
        </div>
      ) : null}
      <div className={styles.districtController}>
        <div
          className={styles.marksButton}
          onClick={onMarksButtonClick}
        >
          下钻地图
        </div>
        {!!activeItem?.id && hasDetailAuth && (
          <div
            className={styles.districtButton}
            onClick={onDetailClick}
          >
            查看详情
          </div>
        )}
      </div>
    </div>
  );
};
