import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import styles from './style.module.less';

interface PageHeaderProps {
  title: string;
  className?: string;
  useBorder?: boolean;
  showBack?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <header
      className={classNames(styles.pageHeader, styles.className, {
        [styles.borderStyle]: props.useBorder,
      })}
    >
      <div className={styles.pageTitle}>
        {props.showBack ? (
          <JLLSvg
            icon="arrow-back"
            size={20}
            color="#01151c"
            className={styles.back}
            onClick={() => history.back()}
          />
        ) : null}
        {props.title}
      </div>
      {props.children}
    </header>
  );
};

export default PageHeader;
