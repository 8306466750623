import dayjs from 'dayjs';
import { JLLDatePicker, Radio, ConfigProvider, JLLSelect, JLLForm } from 'jlld';
import type { RadioChangeEvent } from 'jlld';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import useRelocateTrailStore, {
  setTopDimension,
  queryRelocateTrailListData,
  queryRelocateTrailMapData,
  setDisplayMode,
  hideFirstTimeEnterTip,
} from '../../store';
import { getHalfYearAgoDate, getQuarter, getLastQuarter, getNextQuarter } from '@/utils/tool';
import { PropertyType } from '@/constants/enums';
import { RelocateTrailDimensionType, RelocateTrailModeType } from '../../../../types';
import styles from './style.module.less';
import { radioButtonConfig } from '@/jll';
import useUserAuth from '@/hooks/useUserAuth';
import { getRelocateTrailParams } from '@/pages/Z-ProjectDetail/types/apiTypes';
import { useForm, useWatch } from 'jlld/es/form/Form';
import { useShallow } from 'zustand/react/shallow';

const dimensionTypeOptions = [
  { label: '看迁入', value: RelocateTrailDimensionType.moveIn },
  { label: '看迁出', value: RelocateTrailDimensionType.moveOut },
];

const modeTypeOptions = [
  { label: '地图', value: RelocateTrailModeType.map },
  { label: '列表', value: RelocateTrailModeType.list },
];

const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));
const endTimeStr = `${year}-Q${quarter}`;

const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};

const timeTypeOptions = [
  { label: '近半年', value: RelocateTrailTimeTypeMap.halfYear },
  { label: '近1年', value: RelocateTrailTimeTypeMap.oneYear },
  { label: '近2年', value: RelocateTrailTimeTypeMap.twoYears },
  { label: '近3年', value: RelocateTrailTimeTypeMap.threeYears },
  { label: '自定义', value: RelocateTrailTimeTypeMap.custom },
];

const rangeStart = '2019';
const rangeEnd = `${new Date().getFullYear()}`;

interface TopFilterForm {
  dimension: RelocateTrailDimensionType;
  time: (typeof RelocateTrailTimeTypeMap)[keyof typeof RelocateTrailTimeTypeMap];
  rangeTime: [string, string];
  mode: RelocateTrailModeType;
}

export default () => {
  const [form] = useForm<TopFilterForm>();
  const { data: mainFrameData } = useMainFrameContext();
  const { mode, dimension } = useRelocateTrailStore(
    useShallow((state) => ({ mode: state.displayMode, dimension: state.dimension }))
  );
  const { id: propertyId, type } = mainFrameData.params;

  const time = useWatch('time', form);

  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);

  const propertyType = type === 'business' ? PropertyType.commercial : PropertyType.industrial;

  const hasListAuth = useUserAuth('front:project:relocationtrace:list');

  const onFormValueChange = (changedValues: Partial<TopFilterForm>, allValues: TopFilterForm) => {
    if (changedValues.time === RelocateTrailTimeTypeMap.custom) {
      return;
    }

    if (changedValues.dimension) {
      setTopDimension(changedValues.dimension);
    }

    if (changedValues.mode) {
      setDisplayMode(changedValues.mode);
    }

    const params = {
      propertyId,
      propertyType,
      dimension: allValues.dimension,
    } as getRelocateTrailParams;

    if (changedValues.rangeTime) {
      params.startTime = changedValues.rangeTime[0];
      params.endTime = changedValues.rangeTime[1];
    } else {
      params.startTime = allValues.time;
      params.endTime = endTimeStr;
    }

    if (changedValues.mode === RelocateTrailModeType.list) {
      queryRelocateTrailListData(params);
    } else {
      queryRelocateTrailMapData(params);
    }

    if (trailMapData?.otherProperty?.length > 0) {
      hideFirstTimeEnterTip();
    }
  };
  return (
    <div className={styles.topFilter}>
      <JLLForm
        form={form}
        layout="inline"
        initialValues={{
          time: timeTypeOptions[3].value,
          dimension,
          mode,
        }}
        onValuesChange={onFormValueChange}
      >
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="dimension"
            style={{ marginRight: 0 }}
          >
            <Radio.Group
              options={dimensionTypeOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </JLLForm.Item>
        </ConfigProvider>
        <JLLForm.Item name="time">
          <JLLSelect
            className={styles.timeSelect}
            options={timeTypeOptions}
          />
        </JLLForm.Item>
        {time === RelocateTrailTimeTypeMap.custom ? (
          <JLLForm.Item
            name="rangeTime"
            style={{ marginLeft: '8px' }}
          >
            <JLLDatePicker.JLLRangePicker
              allowClear={false}
              picker="year"
              separator="至"
              className={styles.transactionTimeRangePicker}
              minDate={dayjs(rangeStart)}
              maxDate={dayjs(rangeEnd)}
            />
          </JLLForm.Item>
        ) : (
          <></>
        )}
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="mode"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            hidden={!hasListAuth}
          >
            <Radio.Group
              options={modeTypeOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </JLLForm.Item>
        </ConfigProvider>
      </JLLForm>
    </div>
  );
};
