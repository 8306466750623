import useMapStore from '@/store/mapStore';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';
import DistrictAndMarketInfoCard from '../DistrictAndMarketInfoCard';
import { useEffect, useMemo } from 'react';
import { useMapContext } from '@uiw/react-amap-map';
import { useCity } from '@/store/reactShareSelectedStore';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import RegionPolygon from '../RegionPolygon';
import { heatMapConfig, polygonKey } from './config';

export default () => {
  const { map } = useMapContext();
  const city = useCity();
  const { districtMapDataList, subMarketMapDataList } = useMapStore((state) => ({
    districtMapDataList: state.districtMapDataList,
    subMarketMapDataList: state.subMarketMapDataList,
  }));
  const {
    mapMode,
    toggleMapMode,
    boundariesType,
    queryPageData,
    formData,
    setSubMarketScale,
    summary,
  } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    boundariesType: state.boundariesType,
    formData: state.formData,
    queryPageData: state.queryPageData,
    setSubMarketScale: state.setSubMarketScale,
    toggleMapMode: state.toggleMapMode,
    summary: state.summary,
  }));
  const showPolygn = ['districtAndMarketMode', 'heatMapMode', 'areaMarksMode'].some(
    (mode) => mode === mapMode
  );
  const polygnStyle = mapMode === 'heatMapMode' || mapMode === 'areaMarksMode' ? heatMapConfig : {};

  useEffect(() => {
    // 切换城市、行政区、子市场时需要重新设定地图zooms和地图状态
    (map as any)?.setZooms([8, 13]);
    toggleMapMode('districtAndMarketMode');
  }, [city, boundariesType]);

  useEffect(() => {
    if (mapMode === 'districtAndMarketMode') {
      setSubMarketScale(false);
      queryPageData(formData);
    }
  }, [mapMode]);

  const listener = (zoom: number) => {
    let mode = mapMode;
    if (+zoom > 13 && +zoom <= 20 && mapMode === 'districtAndMarketMode') {
      mode = 'projectMarksMode';
    } else if (+zoom <= 13 && mapMode === 'projectMarksMode') {
      mode = 'districtAndMarketMode';
    } else if (+zoom > 13 && +zoom <= 16 && mapMode === 'heatMapMode') {
      mode = 'areaMarksMode';
    } else if (+zoom <= 16 && mapMode === 'heatProjectMarksMode') {
      mode = 'areaMarksMode';
    } else if (+zoom > 16 && mapMode === 'areaMarksMode') {
      // mode = 'heatProjectMarksMode';
    } else if (+zoom <= 13 && mapMode === 'areaMarksMode') {
      mode = 'heatMapMode';
    }
    if (mode === 'districtAndMarketMode') {
      // 从撒点状态缩放到行政区子市场状态需要重新设定地图zooms
      (map as any)?.setZooms([8, 13]);
    } else if (mode === 'heatMapMode') {
      (map as any)?.setZooms([8, 20]);
    }
    if (mode !== mapMode) {
      toggleMapMode(mode);
    }
  };
  useZoomChange(listener, [mapMode]);

  const districtPathList = useMemo(() => {
    const districtList = districtMapDataList.filter((district) =>
      formData.districtAndSubMarket?.district?.some((name) => name === district.name)
    );
    return districtList.map((item) => ({
      ...item,
      boundaries: item.boundaries.map((e) => [e]),
      isEmpty: !summary?.industryCompareAnalysis?.find((e) => e.name === item.name)?.tenantCount,
    }));
  }, [formData.districtAndSubMarket, districtMapDataList, summary]);

  const districtPolygonList = useMemo(() => {
    if (showPolygn && boundariesType === 'district') {
      return (
        <RegionPolygon
          id="adcode"
          key={(polygonKey as any)[mapMode]}
          pathList={districtPathList}
          {...polygnStyle}
        >
          <DistrictAndMarketInfoCard />
        </RegionPolygon>
      );
    }
    return null;
  }, [mapMode, boundariesType, districtPathList, polygnStyle]);

  const subMarketPathList = useMemo(() => {
    const subMarketList = subMarketMapDataList.filter((subMarket) =>
      formData.districtAndSubMarket?.subMarket?.some((id) => id === subMarket.id)
    );
    return subMarketList.map((item) => ({
      ...item,
      isEmpty: !summary?.industryCompareAnalysis?.find((e) => e.name === item.name)?.tenantCount,
    }));
  }, [formData.districtAndSubMarket, subMarketMapDataList, summary]);

  const subMarketPolygonList = useMemo(() => {
    if (showPolygn && boundariesType === 'subMarket') {
      return (
        <RegionPolygon
          id="id"
          key={(polygonKey as any)[mapMode]}
          pathList={subMarketPathList}
          {...polygnStyle}
        >
          <DistrictAndMarketInfoCard />
        </RegionPolygon>
      );
    }
    return null;
  }, [mapMode, boundariesType, subMarketPathList, polygnStyle]);

  return (
    <>
      {districtPolygonList}
      {subMarketPolygonList}
    </>
  );
};
