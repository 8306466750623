export enum AirConditionerSystem {
  VAV = 1,
  VRV,
  fanCoil,
  others,
}

export enum ElectricSystem {
  dualCircuitPower = 1,
  nonDualCircuitPower,
  tripleCircuitPower,
  others,
}

export enum ProjectLabel {
  luxuryHotel = 1,
  delicateManagement,
  firstClassService,
  subwayAccessibleWithinOneKilometer,
}

export enum PropertyType {
  commercial = '1',
  industrial = '2',
}

export enum ProjectType {
  future = '1',
  built = '2',
}

export enum ProjectLevel {
  gradeA = '1',
  gradeB = '2',
  gradeC = '3',
  gradeD = '4',
}

export enum IndustrialOffice {
  industrialPark = '1',
  industrialBuilding = '2',
}

export enum DeveloperType {
  traditionalRealEstateDeveloper = '1',
  governmentOrPlatformDeveloper = '2',
  professionalIndustrialParkDeveloper = '3',
  industryLeaderEnterprise = '4',
  undecided = '5',
}

export enum FreshAirSystem {
  no,
  yes,
}

export enum LeaseType {
  existing = '1',
  new = '2',
  vacating = '3',
}

export enum TitleForm {
  single = '1',
  distribution = '2',
  partial = '3',
  pending = '4',
}

export enum EnterpriseSource {
  foreignInvestedEnterprises = '1',
  domesticEnterprisesOutsideProvince = '2',
  domesticEnterprisesWithinProvince = '3',
  other = '4',
}

export enum HeadQuarters {
  no = '0',
  yes = '1',
  pending = '2',
}

export enum LeedLevel {
  platinum = '1',
  gold = '2',
  silver = '3',
  certified = '4',
  precertified = '5',
  none = '6',
  pending = '7',
}

// 单一业权,散售业权,部分散售,待定
export enum ProjectTitle {
  singleOwnership = '1',
  scattered = '2',
  partialScattered = '3',
  pending = '4',
}
// 外资, 中资, 本土, 待定
export enum DeveloperBackground {
  foreign = '1',
  domestic = '2',
  local = '3',
  pending = '4',
}
