import React, { useEffect } from 'react';
import { useForm } from 'jlld/es/form/Form';
import DataAnalysisTopForm from '@/pages/F-DataAnalysis/components/DataAnalysisTopForm';
import ProjectOwnershipFormItem from '@/components/CommonFormItems/ProjectOwnershipFormItem';
import DevelopersFormItem from '@/components/CommonFormItems/DevelopersFormItem';
import LeedLevelsFormItem from '@/components/CommonFormItems/LeedLevelsFormItem';
import ProjectLevelFormItem from '@/components/CommonFormItems/ProjectLevelFormItem';
import CitysFormItem from '@/components/CommonFormItems/CitysFormItem';
import DistrictsFormItem from '@/components/CommonFormItems/DistrictsFormItem';
import SubmarketsFormItem from '@/components/CommonFormItems/SubmarketsFormItem';
import { TopFormValues } from '@/pages/F-DataAnalysis/types/settingTypes';
import useDealAnalysisStore, { getTimeRange, queryCurrentTabData, setLoading } from '../../store';
import useDealAnalysisSettingStore, {
  getDefaultTime,
  initTopFormValues,
  initValues,
  setSettingData,
} from '../../store/settingStore';
import IndustrialPickerFormItem from '@/components/CommonFormItems/IndustrialPickerFormItem';
import CompetitionGroupsFormItem from '@/components/CommonFormItems/CompetitionGroupsFormItem';
import ProjectOrIndustryFormItem from '@/components/CommonFormItems/PropertyTypeFormItem';
import EnterpriseFormItem from '@/components/CommonFormItems/EnterpriseFormItem';
import { Form, JLLDatePicker, JLLForm, Radio } from 'jlld';
import DeliveryTimeFormItem from '@/pages/F-DataAnalysis/components/DeliveryTimeFormItem';
import MultiSelectorRow from '@/pages/F-DataAnalysis/components/MultiSelectorRow';
import DataSliceSetting from '@/pages/F-DataAnalysis/components/DataSliceSetting';
import { DataType, customTenantArea } from '@/pages/F-DataAnalysis/config';
import { getOptions } from '@/pages/F-DataAnalysis/method';
import styles from '../../../../style.module.less';

interface TopFormProps {
  tabModuleName: string;
}

const TopForm = (props: TopFormProps) => {
  const [form] = useForm<TopFormValues>();
  const { topFormValues, multiSelectorOptions } = useDealAnalysisSettingStore((state) => ({
    topFormValues: state.topFormValues,
    multiSelectorOptions: state.multiSelectorOptions,
  }));
  const { loading, yearList, quarterList } = useDealAnalysisStore((state) => ({
    loading: state.loading,
    yearList: state.yearList,
    quarterList: state.quarterList,
  }));
  let minMaxDate = ['', ''];
  if (quarterList?.length) {
    minMaxDate = quarterList;
  } else if (yearList?.length) {
    minMaxDate = yearList;
  }
  useEffect(() => {
    Promise.all([getTimeRange(topFormValues.citys!), initTopFormValues()]).then(() => {
      setLoading('topForm', false);
    });
  }, []);

  return (
    <DataAnalysisTopForm<TopFormValues>
      tabModuleName={props.tabModuleName}
      form={form}
      initialValues={topFormValues}
      initValues={initValues}
      loading={loading.topForm}
      onSubmit={(topFormValues) => {
        // 然后写一个大家一起用的format 函数
        setSettingData({ topFormValues });
        queryCurrentTabData();
      }}
      onReset={async (topFormValues) => {
        const { analyzeEndTime } = await getDefaultTime(topFormValues.citys!);
        form.setFieldValue('analyzeEndTime', analyzeEndTime);
        setSettingData({ topFormValues: { ...topFormValues, analyzeEndTime } });
        queryCurrentTabData();
      }}
      onValuesChange={async (value) => {
        if (value.citys?.length) {
          getTimeRange(value.citys);
          const { analyzeEndTime } = await getDefaultTime(value.citys);
          form.setFieldValue('analyzeEndTime', analyzeEndTime);
        }
      }}
      switch={
        <Form.Item name="leaseType">
          <Radio.Group>
            <Radio value={DataType.newDeal}>新成交</Radio>
            <Radio value={DataType.newLeaseTermination}>新退租</Radio>
          </Radio.Group>
        </Form.Item>
      }
      footer={
        <React.Fragment>
          <DeliveryTimeFormItem />
          <JLLForm.Item
            name="customTenantArea"
            noStyle
          >
            <MultiSelectorRow
              label="租户面积段(㎡):"
              labelWidth={130}
              withPending
              selectAllOnOptionsChange
              options={getOptions(multiSelectorOptions.customTenantArea, customTenantArea, 999999)}
            />
          </JLLForm.Item>
          <DataSliceSetting
            onChange={(values) => {
              setSettingData({ multiSelectorOptions: values });
            }}
            optionsList={[{ label: '租户面积段(㎡)', key: 'customTenantArea' }]}
            value={multiSelectorOptions}
          />
        </React.Fragment>
      }
    >
      <CitysFormItem />
      <IndustrialPickerFormItem />
      <EnterpriseFormItem />
      <DistrictsFormItem cityList={form.getFieldValue('citys')} />
      <SubmarketsFormItem cityList={form.getFieldValue('citys')} />
      <ProjectOrIndustryFormItem />
      <DevelopersFormItem />
      <ProjectOwnershipFormItem />
      <LeedLevelsFormItem />
      <CompetitionGroupsFormItem />
      <Form.Item
        name="analyzeEndTime"
        noStyle
      >
        <JLLDatePicker.JLLQuarterPicker
          className={styles.dataAnalysiDatePicker}
          allowClear={false}
          type={topFormValues.analyzeEndTime?.includes('Q') ? 'quarter' : 'year'}
          minMaxDate={minMaxDate}
        />
      </Form.Item>
    </DataAnalysisTopForm>
  );
};

export default TopForm;
