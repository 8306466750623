import { minAndMax } from '@/utils/chart';
import ReactECharts from 'echarts-for-react';
import { ProjectRowData } from '../../types';
import { useMemo, useRef } from 'react';
import Empty from '@/components/Empty/index.tsx';
import { barStyles } from './config';

export default (props: { legend: string[]; data: ProjectRowData[][]; unit?: string }) => {
  const { legend, data, unit } = props;
  const chartRef = useRef<ReactECharts>(null);

  const hasData = useMemo(() => {
    return data.some(
      (item) =>
        !!item?.filter((item) => item.specificField !== null && item.specificField !== undefined)
          .length
    );
  }, [data]);

  const getOptions = () => {
    const colors = ['#FF838B'];
    const xAxis = [...new Set(data.flat(1).map((item) => item.rowTitle))];

    const series: any[] = [];
    legend.forEach((name, index) => {
      series.push({
        type: 'bar',
        symbol: 'circle',
        stack: 'bar',
        z: 10,
        barMaxWidth: 14,
        name,
        ...barStyles[index],
        data: xAxis.map(
          (axis) =>
            data?.[index].filter((item) => item.rowTitle === axis)?.[0]?.specificField ?? '-'
        ),
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            position: 'top',
            padding: [4, 0],
            color: '#525252',
          },
          itemStyle: {
            color: '#E30613',
          },
        },
        blur: {
          itemStyle: {
            opacity: 0.4,
          },
        },
        markArea: {
          silent: true,
          data: [
            [
              {
                itemStyle: {
                  color: '#EEEFF0',
                },
                yAxis: 0,
              },
              {
                yAxis: Number.MIN_SAFE_INTEGER,
              },
            ],
          ],
        },
        markLine: {
          silent: true,
          symbol: 'none',
          symbolSize: 0,
          lineStyle: {
            type: 'solid',
          },
          data: [
            {
              itemStyle: {
                color: '#525252',
              },
              label: {
                show: false,
              },
              yAxis: 0,
            },
          ],
        },
        barStyles,
      });
    });
    return {
      color: colors,
      title: {
        show: xAxis.length === 0,
        text: '暂无数据',
        left: 'center',
        top: 'center',
        textStyle: {
          color: '#909399',
          fontSize: 16,
          fontWeight: 400,
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'line',
        },
        formatter: (params: any) => {
          if (chartRef.current) {
            chartRef.current.getEchartsInstance().dispatchAction({
              type: 'downplay',
            });
          }
          const param = params.filter((item: any) => item.value !== '-');
          const [{ axisValue, seriesName, value }] = param.length ? param : params;
          return `<div>
              ${axisValue}<br />
              <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${colors};"></span>
              <span style="display:inline-block;width:${
                seriesName.length * 12 + 20
              }px">${seriesName}</span>${value} ${unit}</div>`;
        },
      },
      legend: {
        show: true,
        top: 15,
        right: 0,
        borderRadius: 0,
        data: legend.map((name, index) => ({
          name,
          ...barStyles[index],
        })),
        itemWidth: 12,
        itemHeight: 12,
        icon: 'rect',
        textStyle: {
          color: '#4A4E57',
          fontSize: 12,
        },
      },
      grid: {
        left: 10,
        right: '5%',
        bottom: 5,
        top: 50,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxis,
          show: xAxis.length !== 0,
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#525252',
              fontSize: 12,
            },
            align: 'center',
            verticalAlign: 'top',
            margin: 32,
            rotate: 45,
          },
          axisLine: {
            onZero: false,
          },
        },
      ],
      yAxis: [
        {
          name: unit,
          show: xAxis.length !== 0,
          ...minAndMax(series),
          axisLabel: {
            textStyle: {
              color: '#525252',
            },
            margin: 20,
          },
          axisLine: { show: false },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#EDEDED'],
            },
          },
        },
      ],
      series,
    };
  };
  if (!hasData) {
    return <Empty />;
  }
  return (
    <ReactECharts
      ref={chartRef}
      option={getOptions()}
      notMerge
      lazyUpdate
    />
  );
};
