import './style.less';
import styles from './style.module.less';
import { RentalPerformance, ChartView, TopForms } from './views';
import useMarketPerformanceStore from './store';
import classNames from 'classnames';
import { useEffect } from 'react';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useShallow } from 'zustand/react/shallow';

export default () => {
  const { data } = useMainFrameContext();
  const { initProjectExistsTime, loadChartData } = useMarketPerformanceStore(
    useShallow((state) => ({
      initProjectExistsTime: state.initProjectExistsTime,
      loadChartData: state.loadChartData,
    }))
  );

  useEffect(() => {
    initProjectExistsTime(data.params.id, data.params.type).then(() => {
      loadChartData(data.params.id, data.params.type);
    });
  }, []);

  return (
    <div className={classNames('page marketPerformancePage', styles.marketPerformancePage)}>
      <TopForms />
      <div className={styles.scrollWrap}>
        <RentalPerformance />
        <ChartView />
      </div>
    </div>
  );
};
