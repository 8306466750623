import { message } from 'jlld';
import { cityListToCityHeads } from '@/utils/tool';
import { TableDataItem, TopFormValues, tableSettingValues } from './types/settingTypes';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { CommonRequestParams, Segment } from './types/apiTypes';
import { PropertyType, ProjectLevel } from '@/constants/enums';
import { Q2QIndicators, TableTitle, UnitType, Y2YIndicators, unitOptions } from './config';
import { generateRentUnit } from '@/components/UnitExchange/method';

const getSegmentParams = (segmentList: string[] | undefined): Segment[] => {
  if (!segmentList?.length) {
    return [];
  }
  return segmentList
    .filter((item) => item !== '待定')
    .map((segment) => {
      const [startValue, endValue] = segment.split('-');
      return { startValue, endValue };
    });
};

const isYear = (time: string) => !time.includes('Q');

export const topFormValuesToParams = (values: TopFormValues): CommonRequestParams | null => {
  const {
    citys,
    districts,
    submarkets,
    dateValue,
    analyzeStartTime,
    analyzeEndTime,
    analysisTime,
    propertyType,
    vacancySegment,
    bookRentSegment,
    proManagementFeesSegment,
    customTenantArea,
    secondaryIndustries,
    ...leftParams
  } = values;
  const params = {
    ...leftParams,
  } as CommonRequestParams;
  if (!citys || citys.length === 0) {
    message.warning('请至少选择一个城市');
    return null;
  }
  console.log('topform', params);
  params.cityHeads = cityListToCityHeads(citys, districts, submarkets);
  params.isYear = false;

  if (propertyType) {
    params.projectLevel = propertyType[PropertyType.commercial];
    params.industryProjectLevel = propertyType[PropertyType.industrial];
  }

  if (secondaryIndustries) {
    params.twoIndustry = Object.values(secondaryIndustries).flat(1);
  }

  if (dateValue?.length) {
    const [analyzeStartTime, analyzeEndTime] = dateValue;
    params.analyzeStartTime = analyzeStartTime;
    params.analyzeEndTime = analyzeEndTime;
    params.isYear = isYear(analyzeEndTime);
  }

  if (analyzeEndTime) {
    // start和end保持同步
    if (analyzeStartTime) {
      params.analyzeStartTime = analyzeStartTime;
    } else {
      params.analyzeStartTime = analyzeEndTime;
    }
    params.analyzeEndTime = analyzeEndTime;
    params.isYear = isYear(analyzeEndTime);
  }

  if (analysisTime) {
    params.analysisTime = analysisTime;
    params.isYear = isYear(analysisTime);
  }
  params.vacancySegment = getSegmentParams(vacancySegment);
  params.bookRentSegment = getSegmentParams(bookRentSegment);
  params.proManagementFeesSegment = getSegmentParams(proManagementFeesSegment);
  params.customTenantArea = getSegmentParams(customTenantArea);
  return params;
};

export const dimensionValuesToParams = (values: string[]) => {
  return {
    analyzeOneRowHead: values[0],
    analyzeTwoRowHead: values[1],
    analyzeThreeRowHead: values[2],
  };
};

export const tableValuesToParams = (values: tableSettingValues) => {
  return {
    dataUnitType: values.unitType,
    summaryType: values.summaryShowType,
  };
};

export const generateQueryParams = (
  topFormValues: TopFormValues,
  dimensionValues: string[],
  tableFormValues: tableSettingValues
): CommonRequestParams | null => {
  const { bookRentSegment, proManagementFeesSegment, vacancySegment, customTenantArea } =
    topFormValues;
  const topParams = topFormValuesToParams(topFormValues);
  if (!topParams) {
    return null;
  }
  const params = {
    ...topParams,
    ...dimensionValuesToParams(dimensionValues),
    ...tableValuesToParams(tableFormValues),
    bookRentSegmentType: bookRentSegment?.includes('待定') ? '1' : '',
    proManagementFeesSegmentType: proManagementFeesSegment?.includes('待定') ? '1' : '',
    vacancySegmentType: vacancySegment?.includes('待定') ? '1' : '',
    customTenantAreaType: customTenantArea?.includes('待定') ? '1' : '',
    hasQ2Q: tableFormValues.dataIndicator.some((indicator) => Q2QIndicators.includes(indicator)),
    hasY2Y: tableFormValues.dataIndicator.some((indicator) => Y2YIndicators.includes(indicator)),
  };

  if (params.deliveryTime) {
    params['deliveryStartTime'] = params.deliveryTime[0];
    params['deliveryEndTime'] = params.deliveryTime[1];
  }

  return params;
};

// getOptions
export const getOptions = (
  optionsA: string[] | undefined,
  optionsB: string[],
  maxValue: number
) => {
  return optionsA?.length
    ? [...optionsA, `${optionsA[optionsA.length - 1].split('-')[1]}-${maxValue}`]
    : optionsB;
};
// 返回数据不采用Map因为确认key值都是string，不会出现顺序问题
export const generateCommonTableData = (data: TableDataItem[]) => {
  if (data) {
    const summaryData = data.find((item) => item.dynamicColOne === '总体汇总') || null;

    const leftData = data.filter((item) => item.dynamicColOne !== '总体汇总');
    const tableData: Record<string, TableDataItem[]> = {};

    if (leftData) {
      // 第一列数据
      let currentRowTitle: string;
      let oneMergedDataList: TableDataItem[];
      leftData.forEach((item) => {
        if (
          item.dynamicColOne &&
          currentRowTitle !== item.dynamicColOne &&
          item.dynamicColOne !== '分类汇总'
        ) {
          currentRowTitle = item.dynamicColOne as string;
          // 初始化属于这个title的合并数据
          oneMergedDataList = [];
          tableData[currentRowTitle] = oneMergedDataList;
        }
        oneMergedDataList.push(item);
      });
    }

    return {
      summaryData,
      data: tableData,
    };
  }

  return { summaryData: null, data: null };
};

export const getUnit = (
  { unit, controlByCommonConfig, controlByUnitType }: TableTitle,
  unitType: UnitType
) => {
  if (unit) {
    if (controlByCommonConfig) {
      return `(${generateRentUnit(controlByCommonConfig)})`;
    }
    if (controlByUnitType) {
      return `(${unitOptions.find((item) => item.value === unitType)!.label})`;
    }
    return `(${unit})`;
  }
  return '';
};

/**
 * 根据用户权限获取默认的物业类型
 */
export const getDefaultProperty = () => {
  const user = useReactShareUserStore.getState().user;
  const result = {
    [PropertyType.commercial]: [] as ProjectLevel[],
    [PropertyType.industrial]: [] as ProjectLevel[],
  };

  if (user.propertyType?.includes(PropertyType.commercial)) {
    result[PropertyType.commercial] = [
      ProjectLevel.gradeA,
      ProjectLevel.gradeB,
      ProjectLevel.gradeD,
    ];
  } else if (user.propertyType?.includes(PropertyType.industrial)) {
    result[PropertyType.industrial] = [ProjectLevel.gradeA, ProjectLevel.gradeB];
  }
  return result;
};
