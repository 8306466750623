import { NORMALSTYLE } from '@/constants';
import useMainStore from '@/store/main';
import { useCity } from '@/store/reactShareSelectedStore';
import { JLLMap } from 'jlld';
import PropertyMarks from './PropertyMarks';
import styles from './style.module.less';
import DistrictAndMarketPolygon from './DistrictAndMarketPolygon';
import MapModeSwitch from './MapModeSwitch';
import HeatMap from './HeatMap';
import AreaMarks from './AreaMarks';
import MapLegend from './MapLegend';
import useIndustrialStore from '../../store';
import { useEffect } from 'react';
import Controler from 'jlld/es/JLLMap/Controler';

export default () => {
  const authCityData = useMainStore((state) => state.authCityData);
  const {
    mapMode,
    center: mapCenter,
    setCenter,
  } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    center: state.center,
    setCenter: state.setCenter,
  }));
  const city = useCity();

  const { center } = authCityData[city];
  useEffect(() => {
    return () => {
      setCenter(null);
    };
  }, []);
  return (
    <div className={styles.mapView}>
      <JLLMap
        animateEnable={false}
        zooms={[8, 13]}
        mapStyle={NORMALSTYLE}
        center={mapCenter?.length ? mapCenter : [...center]}
      >
        <DistrictAndMarketPolygon />
        <PropertyMarks />
        <HeatMap />
        <AreaMarks />
        <Controler />
        <MapModeSwitch />
        {mapMode === 'districtAndMarketMode' ? <MapLegend /> : null}
      </JLLMap>
    </div>
  );
};
