import { useState, useEffect } from 'react';
import { Table, TableColumnType } from 'jlld';
import useUserEvent from '@/hooks/useUserEvent';
import ColumnHeaderWithSortAsOne from '@/components/ColumnHeaderWithSort/arrowAsOne';
import useRelocateTrailStore, { sortTrailTableData } from '../../store';
import Empty from '@/components/Empty';
import { PageWithWatermark } from '@/components';
import { RelocateTrailDimensionType } from '../../../../types';
import styles from '../../style.module.less';

interface DataType {
  key: string;
  statisticsName: string;
  count: string;
  saleArea: string;
  totalPrice: string;
  unitPrice: string;
}

const DataTable = () => {
  const [sortKey, setSortKey] = useState('time');
  const [sortAsc, setSortAsc] = useState(false);
  const trailTableData = useRelocateTrailStore((state) => state.trailTableData);
  const dimension = useRelocateTrailStore((state) => state.dimension);

  const { sendUserEvent } = useUserEvent();

  const sortIconClick = (key: string, asc: boolean) => {
    if (trailTableData?.length === 0) {
      return;
    }
    const order = {
      asc,
      column: key,
    };
    setSortKey(key);
    setSortAsc(asc);
    sortTrailTableData(order);
  };

  const cellFormatter = (text: string) => {
    return text || '-';
  };

  const renderTitle = (title: string, key: string, arrowAlign?: string) => {
    return (
      <ColumnHeaderWithSortAsOne
        title={title}
        currentKey={key}
        sortKey={sortKey}
        sortAsc={sortAsc}
        arrowAlign={arrowAlign}
        sortIconClick={sortIconClick}
      />
    );
  };

  const columns: TableColumnType<DataType>[] = [
    {
      title: renderTitle('租户名称', 'tenantName', 'right'),
      dataIndex: 'tenantName',
      key: 'tenantName',
      align: 'left',
      width: '20%',
      render: cellFormatter,
    },
    {
      title: renderTitle(
        `${dimension === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}时间`,
        'time',
        'right'
      ),
      dataIndex: 'time',
      key: 'time',
      align: 'left',
      width: '20%',
      render: cellFormatter,
    },
    {
      title: renderTitle(
        `${dimension === RelocateTrailDimensionType.moveIn ? '来源' : '目标'}项目`,
        'propertyName',
        'right'
      ),
      dataIndex: 'propertyName',
      key: 'propertyName',
      align: 'left',
      width: '20%',
      render: cellFormatter,
    },
    {
      title: '一级行业',
      dataIndex: 'primaryIndustry',
      key: 'primaryIndustry',
      align: 'left',
      width: '20%',
      render: cellFormatter,
    },
    {
      title: '租户来源',
      dataIndex: 'tenantOrigin',
      key: 'tenantOrigin',
      align: 'left',
      width: '20%',
      render: cellFormatter,
    },
  ];

  useEffect(() => {
    sendUserEvent({ page: '项目详情-迁址轨迹-列表' }, 'e_view');
  }, []);

  return trailTableData?.length > 0 ? (
    <div className={styles.dataTableWrap}>
      <PageWithWatermark>
        <Table
          className={styles.detailTable}
          rowClassName={styles.tableRow}
          columns={columns}
          dataSource={trailTableData}
          rowKey={'statisticsName'}
          pagination={false}
        />
      </PageWithWatermark>
    </div>
  ) : (
    <div className={styles.centeredEmpty}>
      <Empty
        text="当前筛选条件下没有相关的迁址记录"
        useSolsticeStyle
      />
    </div>
  );
};

export default DataTable;
