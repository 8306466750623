import { useEffect } from 'react';
import { JLLMap } from 'jlld';
import Controler from 'jlld/es/JLLMap/Controler';
import useAutoFit from 'jlld/es/JLLMap/hooks/useAutoFit';
import { useMapContext } from '@uiw/react-amap-map';
import useProjectDetailStore from '../../../../store';
import useUserEvent from '@/hooks/useUserEvent';
import Empty from '@/components/Empty';
import { NORMALSTYLE } from '@/constants';
import MapLegend from './MapLegend';
import PropertyMarks from './PropertyMarks';
import FirstTimeEnterTip from './FirstTimeEnterTip';
import useRelocateTrailStore, { hideFirstTimeEnterTip } from '../../store';
import { RelocateTrailDimensionType } from '../../../../types';
import styles from './style.module.less';
import BezierPath from './BezierPath';

const Content = () => {
  const dimension = useRelocateTrailStore((state) => state.dimension);
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const showFirstTimeEnterTip = useRelocateTrailStore((state) => state.showFirstTimeEnterTip);
  const { map } = useMapContext();

  useAutoFit([trailMapData?.curProperty]);
  if (!map) {
    return null;
  }

  if (!trailMapData?.curProperty) {
    return (
      <div className={styles.mapEmpty}>
        <Empty
          text="当前筛选条件下没有相关的迁址记录"
          useSolsticeStyle
          noPadding
        />
      </div>
    );
  }

  const center = trailMapData.curProperty?.center;
  const others = trailMapData.otherProperty
    ?.filter((item) => item.propertyId !== trailMapData.curProperty.propertyId)
    .map((item) => item.center);
  return (
    <>
      <Controler controlList={['zoom']} />
      <MapLegend
        subText={
          dimension === RelocateTrailDimensionType.moveIn ? '迁入租户来源项目' : '迁出租户去向项目'
        }
      />
      <PropertyMarks />
      {showFirstTimeEnterTip ? (
        <FirstTimeEnterTip position={trailMapData?.otherProperty[0]?.center} />
      ) : null}
      <BezierPath
        reverse={dimension === RelocateTrailDimensionType.moveIn}
        center={center}
        others={others}
      />
    </>
  );
};

export default () => {
  const { sendUserEvent } = useUserEvent();
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const { projectDetail } = useProjectDetailStore();

  const projectCenter = [
    projectDetail.locationInfo?.longitude,
    projectDetail.locationInfo?.latitude,
  ];

  const initialZoom = 13;

  useEffect(() => {
    sendUserEvent({ page: '项目详情-迁址轨迹-地图' }, 'e_view');
  }, []);

  useEffect(() => {
    if (
      trailMapData?.otherProperty?.length > 0 &&
      !localStorage.getItem('userEnterDetailRelocate')
    ) {
      setTimeout(() => {
        hideFirstTimeEnterTip();
      }, 6000);
    }
  }, [trailMapData?.otherProperty]);

  return (
    <div className={styles.mapView}>
      <JLLMap
        animateEnable={false}
        zoom={initialZoom}
        zooms={[initialZoom - 2, 20]}
        mapStyle={NORMALSTYLE}
        center={trailMapData?.curProperty?.center || projectCenter}
        dragEnable={!!trailMapData?.curProperty}
      >
        <Content />
      </JLLMap>
    </div>
  );
};
