import { JLLSvg, Radio, Table, TableColumnsType, Tooltip } from 'jlld';
import styles from './style.module.less';
import useTenantAnalysisStore from '../../store';
import { FloorDetailItem, FloorIndustryForm, IFSFloorDetail } from '../../types';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useShallow } from 'zustand/react/shallow';
import { formatField } from '@/utils/tool';
import { itemColor } from '@/components/Charts/config';

const queryTypOptions = [
  {
    label: '一级行业类型',
    value: 1,
  },
  {
    label: '二级行业类型',
    value: 2,
  },
  {
    label: '中外资',
    value: 3,
  },
];
const Header = () => {
  const { data } = useMainFrameContext();
  const { tenantIFSData, setQueryType, getTenantIFS } = useTenantAnalysisStore(
    useShallow((state) => ({
      tenantIFSData: state.tenantIFSData,
      setQueryType: state.setQueryType,
      getTenantIFS: state.getTenantIFS,
    }))
  );
  const { occupancyRate, vacantRate } = tenantIFSData || {};

  return (
    <div className={styles.header}>
      <div className={styles.propertyVacant}>
        <div className={styles.item}>
          出租率：<span>{occupancyRate ? `${occupancyRate}%` : '-'}</span>
        </div>
        <div className={styles.item}>
          空置率：<span>{vacantRate ? `${vacantRate}%` : '-'}</span>
        </div>
      </div>
      <div className={styles.filter}>
        显示数据：
        <Radio.Group
          defaultValue={1}
          onChange={(e) => {
            setQueryType(e.target.value);
            getTenantIFS(data.params.id, data.params.type);
          }}
          options={queryTypOptions}
        />
      </div>
    </div>
  );
};

const floorIndustryColumns: TableColumnsType<FloorDetailItem> = [
  {
    title: '企业名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '一级行业',
    dataIndex: 'oneIndustry',
    align: 'center',
  },
  {
    title: '二级行业',
    dataIndex: 'twoIndustry',
    align: 'center',
  },
  {
    title: '中外资',
    dataIndex: 'tenantSource',
    align: 'center',
  },
  {
    title: '租赁面积（㎡）',
    dataIndex: 'leaseArea',
    align: 'center',
  },
];

const FloorIndustry = (props: { floorBusId: string }) => {
  const { floorBusId } = props;
  const floorDetailMap = useTenantAnalysisStore((state) => state.floorDetailMap);
  if (!floorDetailMap[floorBusId]?.open || !floorDetailMap[floorBusId]?.floorDetail) {
    return null;
  }

  return (
    <Table
      className={styles.floorIndustryTable}
      rowKey="name"
      columns={floorIndustryColumns}
      dataSource={floorDetailMap[props.floorBusId].floorDetail}
      pagination={false}
    />
  );
};

const FloorTable = () => {
  const { data } = useMainFrameContext();
  const { tenantIFSData, floorDetailMap, toggleTenantDetail } = useTenantAnalysisStore(
    useShallow((state) => ({
      tenantIFSData: state.tenantIFSData,
      floorDetailMap: state.floorDetailMap,
      toggleTenantDetail: state.toggleTenantDetail,
    }))
  );
  const { floorList, titleList } = tenantIFSData || {};

  const title = (
    <div className={styles.floorTenantTitle}>
      <div>租户分布</div>
      <div className={styles.legend}>
        {tenantIFSData?.titleList?.map((item, index) => (
          <span
            className={styles.item}
            key={item}
          >
            <i style={{ backgroundColor: itemColor[index] }} />
            {item}
          </span>
        ))}
      </div>
    </div>
  );

  const columns: TableColumnsType<IFSFloorDetail> = [
    {
      title: '楼层',
      dataIndex: 'floor',
      width: '70px',
      align: 'center',
    },
    {
      title,
      dataIndex: 'floorTenantList',
      align: 'center',
      className: styles.floorTenantListCell,
      render: (text: string, record: IFSFloorDetail) => {
        return (
          <>
            <div
              className={styles.floorTenantDetail}
              onClick={() => toggleTenantDetail(data.params.type, record.floorBusId as string)}
            >
              <div className={styles.floorTenantList}>
                {record.floorTenantList?.map((item: FloorIndustryForm) => (
                  <Tooltip
                    title={`${item.rowTitle}:${item.specificField}%`}
                    key={item.rowTitle}
                  >
                    <span
                      className={styles.item}
                      style={{
                        width: item.specificField + '%',
                        background: titleList?.length
                          ? itemColor[titleList.findIndex((title) => title === item.rowTitle) % 26]
                          : 'transparent',
                      }}
                    >
                      <span>{item.rowTitle}</span>
                      <span>{item.specificField}%</span>
                    </span>
                  </Tooltip>
                ))}
              </div>
              <JLLSvg
                icon={floorDetailMap?.[record.floorBusId]?.open ? 'up-arrow' : 'down-arrow'}
                color="#4a5057"
                size={22}
              />
            </div>
            <FloorIndustry floorBusId={record.floorBusId} />
          </>
        );
      },
    },
    {
      title: '已租面积占比',
      dataIndex: 'rentedArea',
      width: '120px',
      align: 'center',
      render: (text: string) => {
        const displayText = formatField(text, true);
        return displayText.includes('-') ? displayText : `${text}%`;
      },
    },
    {
      title: '空置面积占比',
      dataIndex: 'vacantArea',
      width: '120px',
      align: 'center',
      render: (text: string) => {
        const displayText = formatField(text, true);
        return displayText.includes('-') ? displayText : `${text}%`;
      },
    },
  ];
  return (
    <Table
      bordered
      rowKey="floor"
      className={styles.floorTable}
      columns={columns}
      dataSource={floorList}
      pagination={false}
    />
  );
};

export default () => {
  return (
    <div className={styles.tenantAnalysisFloorDetail}>
      <Header />
      <FloorTable />
    </div>
  );
};
