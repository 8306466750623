import {
  CityPickerOptionFirstLevel,
  getPlainCompetitionGroups,
  queryCityPickerOptions,
} from '@/api';
import { getChildAdminByCity, getCitySubMarketAndDistrict } from '@/api/map';
import { getMinMaxDate, queryIndustryOptions } from '@/api/options';
import { getDistrictPath } from '@/utils/mapTools';
import { CascaderOption } from 'jlld/es/JLLCascader';
import { immer } from 'zustand/middleware/immer';
import { OptionItem } from 'jlld/es/JLLSelect';
import { create } from 'zustand';
import useMainStore from '../main';
import useMapStore from '../mapStore';
import { CityData } from '@/types';
import { queryDictDataByTypeParams } from '@/types/apiTypes';
import { deepClone } from '@/utils/tool';

export const cityDistrictCache: Record<string, OptionItem[]> = {};

export const citySubMarketCache: Record<string, OptionItem[]> = {};

type PageName =
  | 'industry'
  | 'blockTrade'
  | 'report'
  | 'blockTradeTransactionDetail'
  | 'dataAnalysis';

interface State {
  computed: {
    districtAndSubMarketOptions: any;
  };
  industryOptions: CascaderOption[];
  cityPickerOptions: CityPickerOptionFirstLevel[] | null;
  districtOptions: OptionItem[];
  subMarketOptions: OptionItem[];
  districts: OptionItem[];
  submarkets: OptionItem[];
  dictDataByTypeOptions: Record<queryDictDataByTypeParams, OptionItem[]>;
  competitionGroups: OptionItem[];
  dateRange: [string, string] | null;
  loading: Record<PageName, boolean>;
  hasData: boolean;
}

const initState: State = {
  industryOptions: [],
  cityPickerOptions: null,
  // TODO: 删除下面的Options，用新接口替换
  districtOptions: [],
  subMarketOptions: [],
  districts: [],
  submarkets: [],
  dateRange: null,
  // 这个状态是页面表单数据加载，按需用
  dictDataByTypeOptions: {} as any,
  competitionGroups: [],
  loading: {
    industry: true,
    blockTrade: true,
    report: true,
    blockTradeTransactionDetail: true,
    dataAnalysis: true,
  },
  hasData: true,
};

const useCommonOptionsStore = create<State>()(immer(() => initState));

export const set = useCommonOptionsStore.setState;
export const get = useCommonOptionsStore.getState;

export const setLoading = (pageName: PageName, loading: boolean) => {
  set((state) => {
    state.loading[pageName] = loading;
  });
};

export const queryIndustryOptionsAction = async () => {
  if (!get().industryOptions.length) {
    const industryOptions = await queryIndustryOptions();
    set({
      industryOptions,
    });
  }
};

export const queryCityPickerOptionsAction = async () => {
  if (!get().industryOptions.length) {
    const { authCityData } = useMainStore.getState();
    const cityPickerOptions = await queryCityPickerOptions(authCityData);

    set({
      cityPickerOptions,
    });
  }
};

export const queryDistrictSubmarkets = async (citylist: string[] | string, withMapData = false) => {
  const result = await getCitySubMarketAndDistrict(Array.isArray(citylist) ? citylist : [citylist]);
  const districts = [] as CascaderOption[];
  const submarkets = [] as CascaderOption[];
  result.forEach((cityData: any) => {
    if (cityData.cityName === '多地') {
      districts.push({
        label: '多地',
        value: '多地',
        children: [{ label: '多地', value: '多地' }],
      });
      submarkets.push({
        label: '多地',
        value: '多地',
        children: [{ label: '多地', value: '多地' }],
      });
    } else {
      districts.push({
        label: cityData.cityName,
        value: cityData.cityName,
        children: cityData.districtList?.map((item: any) => ({
          label: item,
          value: item,
        })),
      });
      submarkets.push({
        label: cityData.cityName,
        value: cityData.cityName,
        children: cityData.subMarketList?.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      });
    }
  });

  set((state) => {
    state.districts = districts;
    state.submarkets = submarkets;
    return state;
  });

  if (withMapData) {
    // todo
  }
};

export const queryDistrictAndSubMarketOptions = async (
  cityName: string,
  withoutMapData?: boolean
) => {
  // if (cityDistrictCache[cityName] && citySubMarketCache[cityName]) {
  //   set({
  //     districtOptions: cityDistrictCache[cityName],
  //     subMarketOptions: citySubMarketCache[cityName],
  //   });
  // } else {
  const result = (await getChildAdminByCity(cityName)) as CityData;
  const { authCityData } = useMainStore.getState();

  cityDistrictCache[cityName] = result?.adminAreas.map((item) => ({
    label: item,
    value: item,
  }));

  citySubMarketCache[cityName] = result?.childMarkets.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  set({
    districtOptions: cityDistrictCache[cityName],
    subMarketOptions: citySubMarketCache[cityName],
  });

  if (result.adminAreas?.length && !withoutMapData) {
    const districts = await getDistrictPath(authCityData[cityName].adcode, result.adminAreas);

    useMapStore.setState({
      districtMapDataList: districts,
      subMarketMapDataList: result.childMarkets.filter(
        (item) => !!item?.boundaries && !!item.center
      ),
    });

    return {
      districtOptions: cityDistrictCache[cityName],
      subMarketOptions: citySubMarketCache[cityName],
    };
  }
};

export const queryCityDateRange = async (cityName: string) => {
  const { startTime, endTime } = await getMinMaxDate(cityName);
  set({
    hasData: !!startTime,
    dateRange: [startTime || '', endTime || ''],
  });
};

// 获取当前用户下的所有竞品组（不包含项目那些信息）
export const getCompetitionGroups = async () => {
  const result = await getPlainCompetitionGroups();
  set({
    competitionGroups: result,
  });
};

export const leavePage = (pageName?: PageName) => {
  set(deepClone(initState));
};
export const reset = () => {
  set(deepClone(initState));
};

export default useCommonOptionsStore;
