import { useMapContext } from '@uiw/react-amap-map';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Props } from './types';
import { defaultActiveItemStyle, defaultStyle, emptyStyle, activeEmptyItemStyle } from './config';
import { LabelMarker } from '@/components/LabelMarker';
import { Polygon } from './polygon';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';

export default (props: Props) => {
  const { visible = true, pathList, id, style, activeItemStyle, disabled } = props;
  const { map } = useMapContext();
  const [activeItem, setActiveItem] = useState();
  const timer = useRef<NodeJS.Timeout>();
  const infoWindowOver = useRef<boolean>(false);
  // state 更新没有ref快，也是作为hack使用,避免多次触发over事件
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (map) {
      const polygonList = map.getAllOverlays('polygon');
      map.setFitView(polygonList, true, [0, 0, 0, 0], 13);
    }
  }, [map, pathList]);

  const onMouseOver = (item: any) => {
    if (disabled) {
      return;
    }
    clearTimeout(timer.current);
    if (activeItemRef.current?.[id] === item[id]) {
      return;
    }
    setActiveItem(item);
    activeItemRef.current = item;
  };

  const onMouseOut = () => {
    if (disabled) {
      return;
    }
    if (infoWindowOver.current === true) {
      return;
    }
    timer.current = setTimeout(() => {
      setActiveItem(undefined);
      activeItemRef.current = null;
    }, 200);
  };

  const onWindowMouseOver = () => {
    if (disabled) {
      return;
    }
    infoWindowOver.current = true;
    clearTimeout(timer.current);
  };

  const onWindowMouseOut = () => {
    if (disabled) {
      return;
    }
    infoWindowOver.current = false;
    setActiveItem(undefined);
    activeItemRef.current = null;
  };

  const regionPolygon = useMemo(() => {
    return pathList?.map((item: any) => {
      return (
        <Polygon
          key={item[id]}
          visiable={visible}
          path={item.boundaries}
          {...(style || (item.isEmpty && emptyStyle) || defaultStyle)}
          zIndex={10}
          onMouseOver={() => onMouseOver(item)}
          onMouseOut={onMouseOut}
        />
      );
    });
  }, [pathList, visible, style]);

  const activePolygon = useMemo(() => {
    if (activeItem) {
      return (
        <Polygon
          key={activeItem[id]}
          visiable={visible}
          path={activeItem.boundaries}
          {...(activeItemStyle ||
            (activeItem.isEmpty && activeEmptyItemStyle) ||
            defaultActiveItemStyle)}
          zIndex={20}
          onMouseOver={() => onMouseOver(activeItem)}
          onMouseOut={onMouseOut}
        />
      );
    }
  }, [activeItem]);

  const infoCard = React.cloneElement(props.children, {
    activeItem,
  });

  const infoWindow = useMemo(() => {
    if (activeItem?.isEmpty) {
      return null;
    }
    return (
      <AutoFitInfoWindow
        arrowStyle={{ backgroundColor: '#fff' }}
        position={activeItem?.center}
        visible={!!activeItem?.[id]}
        onMouseEnter={onWindowMouseOver}
        onMouseLeave={onWindowMouseOut}
      >
        {infoCard}
      </AutoFitInfoWindow>
    );
  }, [activeItem?.[id]]);

  return (
    <>
      {regionPolygon}
      {activePolygon}
      {pathList?.map((item) => (
        <LabelMarker
          name={item.name}
          zooms={[8, 13]}
          key={item.name}
          zIndex={200}
          text={{
            content: item.name,
            direction: 'top',
            offset: [0, 0],
            style: {
              strokeColor: '#ffffff',
              fontSize: 14,
              fillColor: '#60666E',
              strokeWidth: 4,
              backgroundColor: 'rgba(0,0,0,0)',
            },
          }}
          position={item.center}
          onMouseOver={(e) => {
            onMouseOver(item);
          }}
          onMouseOut={() => {
            onMouseOut();
          }}
        />
      ))}
      {infoWindow}
    </>
  );
};
