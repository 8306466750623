import './style.less';
import styles from './style.module.less';
import { useEffect } from 'react';
import { ChartView, TopForms } from './views';
import classNames from 'classnames';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import BuildingList from '@/components/BuildingList';
import useTenantAnalysisStore from './store';
import useProjectDetailStore from '../../store';
import { useShallow } from 'zustand/react/shallow';

export default () => {
  const { data } = useMainFrameContext();
  const {
    busId,
    buildingList,
    initExistsTime,
    initBuildingList,
    queryChartData,
    setBusId,
    closeAllTenantDetail,
  } = useTenantAnalysisStore(
    useShallow((state) => ({
      busId: state.busId,
      buildingList: state.buildingList,
      initExistsTime: state.initExistsTime,
      initBuildingList: state.initBuildingList,
      queryChartData: state.queryChartData,
      setBusId: state.setBusId,
      closeAllTenantDetail: state.closeAllTenantDetail,
    }))
  );
  const projectDetail = useProjectDetailStore((state) => state.projectDetail);

  useEffect(() => {
    setBusId(data.params.id);
    initExistsTime(data.params.id, data.params.type).then(() => {
      queryChartData(data.params.id, data.params.type);
    });
    if (data.params.type === 'industry') {
      initBuildingList(data.params.id);
    }
  }, []);

  return (
    <div className={classNames('page tenantAnalysisPage', styles.tenantAnalysisPage)}>
      <TopForms />
      <div className={classNames(styles.tenantAnalysisPanel, styles[data.params.type])}>
        {data.params.type === 'industry' ? (
          <BuildingList
            projectId={data.params.id}
            projectName={projectDetail.baseInfo.cnName}
            busId={busId}
            buildingList={buildingList}
            onChange={(id) => {
              setBusId(id);
              queryChartData(data.params.id, data.params.type);
              closeAllTenantDetail();
            }}
          />
        ) : null}
        <ChartView
          projectName={
            busId && busId !== data.params.id && buildingList.length
              ? buildingList.filter((item) => item.businessId === busId)?.[0]?.buildingName
              : projectDetail.baseInfo.cnName
          }
        />
      </div>
    </div>
  );
};
