import useCommonOptionsStore from '@/store/commonOptions';
import { Form, JLLCascader, JLLForm } from 'jlld';

const SubmarketsFormItem = () => {
  const cityList = Form.useWatch('citys', Form.useFormInstance());

  const submarkets = useCommonOptionsStore((state) => state.submarkets).filter(
    (item) => !cityList?.length || cityList.includes(item.value)
  );

  return (
    <JLLForm.Item
      name="submarkets"
      noStyle
    >
      <JLLCascader
        placeholder="全部子市场"
        search
        options={submarkets}
      />
    </JLLForm.Item>
  );
};

export default SubmarketsFormItem;
