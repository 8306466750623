import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { getRelocateTrailListData, getRelocateTrailMapData } from '../../../api';
import { RelocateTrailDimensionType, RelocateTrailModeType } from '../../../types';
import {
  getRelocateTrailParams,
  getRelocateTrailListRes,
  getRelocateTrailMapRes,
  RelocateTrailMapProperty,
} from '@/pages/Z-ProjectDetail/types/apiTypes';

interface TrailTableOrder {
  asc: boolean;
  column: string;
}

type Dimension = RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
type Mode = RelocateTrailModeType.map | RelocateTrailModeType.list;

interface State {
  dimension: Dimension;
  displayMode: Mode;
  trailTableData: getRelocateTrailListRes[];
  trailMapData: getRelocateTrailMapRes;
  trailTableOrder: TrailTableOrder;
  showFirstTimeEnterTip: boolean;
}

const state: State = {
  dimension: RelocateTrailDimensionType.moveIn,
  displayMode: RelocateTrailModeType.map,
  trailTableData: [],
  trailMapData: {} as getRelocateTrailMapRes,
  trailTableOrder: {
    asc: false,
    column: 'time',
  },
  showFirstTimeEnterTip: !localStorage.getItem('userEnterDetailRelocate'),
};

const useRelocateTrailStore = create<State>()(immer(() => state));

const set = useRelocateTrailStore.setState;
const get = useRelocateTrailStore.getState;

export const queryRelocateTrailListData = async (queryParam: getRelocateTrailParams) => {
  const trailTableData = await getRelocateTrailListData(queryParam);
  set({
    trailTableData,
  });
};

export const queryRelocateTrailMapData = async (queryParam: getRelocateTrailParams) => {
  const trailMapData = await getRelocateTrailMapData(queryParam);
  set({
    trailMapData,
  });
};

export const resetRelocateData = () => {
  set({
    trailTableData: [],
    trailMapData: {
      curProperty: {} as RelocateTrailMapProperty,
      otherProperty: [],
    },
    dimension: RelocateTrailDimensionType.moveIn,
  });
};

export const setTrailTableOrder = (order: TrailTableOrder) => {
  set({
    trailTableOrder: order,
  });
};

export const setTopDimension = (dimension: Dimension) => {
  set({
    dimension,
  });
};

export const setDisplayMode = (displayMode: Mode) => {
  set({
    displayMode,
  });
};

export const hideFirstTimeEnterTip = () => {
  localStorage.setItem('userEnterDetailRelocate', 'true');
  set({
    showFirstTimeEnterTip: false,
  });
};

export const sortTrailTableData = (order: TrailTableOrder) => {
  const { trailTableData } = get();
  const { asc, column } = order;
  const columnNullArr = [] as getRelocateTrailListRes[];
  const columnNotNullArr = [] as getRelocateTrailListRes[];

  trailTableData.forEach((item) => {
    if (item[column]) {
      columnNotNullArr.push(item);
    } else {
      columnNullArr.push(item);
    }
  });

  const temp = columnNotNullArr.sort((prev, next) => {
    const prevLower = prev[column]?.toLowerCase();
    const nextLower = next[column]?.toLowerCase();
    return asc ? prevLower.localeCompare(nextLower) : nextLower.localeCompare(prevLower);
  });
  // 空值列不参与排序，放在最后
  set({
    trailTableOrder: order,
    trailTableData: [...temp, ...columnNullArr],
  });
};

export default useRelocateTrailStore;
