import { PolygonStyle } from './types';

export const defaultStyle: PolygonStyle = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#A9000B',
  fillOpacity: 0.32,
};

export const emptyStyle: PolygonStyle = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#4B5B61',
  fillOpacity: 0.32,
};

export const defaultActiveItemStyle: PolygonStyle = {
  strokeColor: '#E30613',
  strokeWeight: 2,
  fillColor: '#A9000B',
  fillOpacity: 0.48,
};

export const activeEmptyItemStyle: PolygonStyle = {
  strokeColor: '#01151C',
  strokeWeight: 2,
  fillColor: '#4B5B61',
  fillOpacity: 0.48,
};
