import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import useMainStore from './main';
import useReactShareUserStore from './reactShareUserStore';

const useReactShareSelectedStore = create(
  immer(
    combine(
      {
        selectedCity: '',
        selectedTime: '', // 宏观、城市页面时间统一；
      },
      (set) => ({
        setSelectedCity: (selectedCity: string) => set(() => ({ selectedCity })),
        setSeletedTime: (selectedTime: string) => set(() => ({ selectedTime })),
      })
    )
  )
);

export const useCity = () => {
  const authCityData = useMainStore((state) => state.authCityData);
  const selectedCity = useReactShareSelectedStore((state) => state.selectedCity);
  const { localCity, cityId } = useReactShareUserStore((state) => {
    return {
      cityId: state.user?.attentionCity?.cityId,
      localCity: state.localCity,
    };
  });
  if (selectedCity && Reflect.has(authCityData, selectedCity)) {
    return selectedCity;
  }
  if (Reflect.has(authCityData, cityId)) {
    return cityId;
  }

  if (Reflect.has(authCityData, localCity)) {
    return localCity;
  }

  return Object.keys(authCityData)[0];
};

let isSetting = false;

let isSettingVue = false;

const keys = ['selectedCity', 'selectedTime'];

(useReactShareSelectedStore as any).syncState = (state: any) => {
  if (!isSettingVue) {
    isSetting = true;
    // console.log('react receive selected state', state);
    const newState: any = {};

    keys.forEach((key) => {
      newState[key] = state[key];
    });
    useReactShareSelectedStore.setState(newState);
    isSetting = false;
  }
};

useReactShareSelectedStore.subscribe(() => {
  if (!isSetting && (window as any).mainFrameMehtod?.piniaSelectedStore) {
    isSettingVue = true;
    (window as any).mainFrameMehtod
      .piniaSelectedStore()
      .$patch(useReactShareSelectedStore.getState());
    isSettingVue = false;
  }
});

export default useReactShareSelectedStore;
