import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import useUserEvent from '@/hooks/useUserEvent';
import { MapSwitch, mapModeSwitchConfig } from './config';
import styles from './style.module.less';
import classNames from 'classnames';

export default () => {
  const { mapMode, toggleMapMode } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    toggleMapMode: state.toggleMapMode,
  }));
  const { sendUserEvent } = useUserEvent();

  return (
    <div className={styles.mapModeSwitch}>
      {mapModeSwitchConfig.map((config: MapSwitch) => (
        <div
          className={classNames(styles.switch, {
            [styles.active]: config.active.some((mode) => mode === mapMode),
          })}
          key={config.mode}
          onClick={() => {
            sendUserEvent(
              { button: config.mode === 'heatMapMode' ? '地图区域切换' : '地图热力图切换' },
              'e_click'
            );
            toggleMapMode(config.mode);
          }}
        >
          {config.name}
        </div>
      ))}
    </div>
  );
};
