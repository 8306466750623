import PageHeader from '@/components/PageHeader';
import useCommonOptionsStore, { setLoading } from '@/store/commonOptions';
import { useCity } from '@/store/reactShareSelectedStore';
import useReactShareUserStore from '@/store/reactShareUserStore';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import useUserEvent from '@/hooks/useUserEvent';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import ChartView from './views/ChartView';
import MapView from './views/MapView';
import TopForms from './views/TopForms';
import './style.less';
import './style.module.less';
import styles from './style.module.less';
import { queryIndustryPageOptions } from '@/store/commonOptions/pageActions';

const IndustrialPage = (props: any) => {
  const $page = useRef<HTMLDivElement>(null);
  const { loading, hasData } = useCommonOptionsStore((state) => ({
    loading: state.loading,
    hasData: state.hasData,
  }));
  const user = useReactShareUserStore((state) => state.user);
  const summary = useIndustrialStore((state) => state.summary);
  const city = useCity();
  const { sendUserEvent } = useUserEvent();

  const renderNoDataTip = () => {
    if (hasData && summary?.enterpriseCount === 0 && summary?.propertyCount === 0) {
      return (
        <div className={classNames(styles.NoDataTip, styles.mapCenter)}>
          当前条件下无符合条件的产业数据，请更换条件后查看，如有更多疑问发送邮件到ruijian.data@jll.com
        </div>
      );
    } else if (summary === null || !hasData) {
      return (
        <div className={styles.NoDataTip}>
          当前城市暂无数据，如想了解该城市的产业布局结构，欢迎发送邮件至ruijian.data@jll.com
        </div>
      );
    }
    return null;
  };

  const list = user.propertyType?.includes('1')
    ? [
        [1, '1'],
        [1, '2'],
        [1, '4'],
      ]
    : [
        [2, '1'],
        [2, '2'],
      ];

  localStorage.setItem('levelsForCommercialDetail', JSON.stringify(list));

  useEffect(() => {
    sendUserEvent({ page: '产业分析' }, 'e_view');
    queryIndustryPageOptions(city);
    return () => {
      // 立刻页面重置loading，否则在其他页面选了城市，再次进入产业分析form会串
      setLoading('industry', true);
    };
  }, []);

  if (loading.industry) {
    // vendor代码里有全局loading，没法使用pageLoading
    return null;
  }

  return (
    <div
      ref={$page}
      className={classNames('page industrialPage', styles.industrialPage)}
    >
      <PageHeader title="产业分析">
        <TopForms pageElement={$page} />
      </PageHeader>
      <div className={styles.MainContainer}>
        {renderNoDataTip()}
        <MapView />
        <ChartView />
      </div>
    </div>
  );
};

export default IndustrialPage;
