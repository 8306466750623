import { Tooltip, JLLSvg } from 'jlld';
import React, { useEffect, useState, useRef } from 'react';
import { thousandSplitNumber } from '@/utils/tool';
import DevTypeDialog from '@/components/DevTypeDialog';
import useProjectDetailStore from '../../../../store';
import useProjectInfoStore from '../../store';
import { businessInfomation, industryInfomation, buildingInfomation } from '../../config';
import './style.less';

interface GridInfoProps {
  type: string;
  showBuilding: boolean;
}

const GridInfo = (props: GridInfoProps) => {
  const { type, showBuilding } = props;
  const [doubleGrid, setDoubleGrid] = useState(true);
  const [gridData, setGridData] = useState([] as any);
  const { projectDetail } = useProjectDetailStore();
  const { buildingInfo } = useProjectInfoStore();

  const projectInfoContainerRef = useRef<HTMLDivElement>(null);

  const setValue = (children: any, res: any) => {
    children.forEach((item: any) => {
      if (item.type === 'image') {
        item.imageConfig = res[item.key1][item.key2];
        item.previewList = res[item.key1][item.key2]?.map((file: any) => `${file?.fileUrl}`);
      } else {
        item.formConfig.forEach((it: any) => {
          if (
            (props.type === 'business' &&
              it.key1 === 'hardwareInfo' &&
              !projectDetail.hasProjPerms) ||
            (props.type === 'industry' &&
              it.key1 === 'hardwareInfoVO' &&
              !projectDetail.hasProjPrem)
          ) {
            it.value = '暂无权限';
            return;
          }
          it.value =
            it.key2 === 'officeBuildArea'
              ? thousandSplitNumber(res[it.key1][it.key2])
              : res[it.key1]?.[it.key2];
        });
      }
    });
  };

  const setBuildingValue = (children: any, res: any) => {
    const { hasProjPrem } = res;
    children.forEach((item: any) => {
      item.formConfig.forEach((it: any) => {
        if (it.key1 === 'hardwareInfoVO' && !hasProjPrem) {
          it.value = '暂无权限';
          return;
        }
        it.value = res[it.key2];
      });
    });
  };

  const getGridData = (infomation: any) => {
    const newData = [];

    infomation[0].children
      .filter((item) => item.type === 'form')
      .forEach((item) => {
        newData.push({ title: item.subTitle, children: item.formConfig });
      });

    const data1 = infomation[1].children.reduce((prev, curr) => {
      if (curr.formConfig) {
        prev.push(...curr.formConfig);
      }

      return prev;
    }, []);

    const data2 = infomation[2].children.reduce((prev, curr) => {
      if (curr.formConfig) {
        prev.push(...curr.formConfig);
      }

      return prev;
    }, []);

    newData.push({ title: infomation[1].title, children: data1 });
    newData.push({ title: infomation[2].title, children: data2 });
    if (infomation[3]) {
      const data3 = infomation[3].children.reduce((prev, curr) => {
        if (curr.formConfig) {
          prev.push(...curr.formConfig);
        }
        return prev;
      }, []);
      newData.push({ title: infomation[3].title, children: data3 });
    }
    if (doubleGrid) {
      newData.forEach((item) => {
        const halfLenght = Math.ceil(item.children.length / 2);
        const leftHalf = item.children.slice(0, halfLenght);
        const rightHalf = item.children.slice(halfLenght, item.children.length);

        item.children = [leftHalf, rightHalf];
      });
    }

    setGridData(newData);
    // return newData;
  };

  const dataReduceFunc = (prev: any, curr: any) => {
    if (curr.formConfig) {
      prev.push(...curr.formConfig);
    }
    return prev;
  };

  const getBuildingGridData = () => {
    const newData = [];
    let temp;
    buildingInfomation.forEach((item: any) => {
      temp = item.children.reduce(dataReduceFunc, []);
      newData.push({ title: item.title, children: temp });
    });

    if (doubleGrid) {
      newData.forEach((item) => {
        const halfLenght = Math.ceil(item.children.length / 2);
        const leftHalf = item.children.slice(0, halfLenght);
        const rightHalf = item.children.slice(halfLenght, item.children.length);

        item.children = [leftHalf, rightHalf];
      });
    }
    setGridData(newData);
  };

  useEffect(() => {
    const setGridType = () => {
      if (projectInfoContainerRef.current) {
        const { width } = projectInfoContainerRef.current?.getBoundingClientRect();
        setDoubleGrid(width > 1020);
      }
    };
    const observer = new window.ResizeObserver(() => {
      setGridType();
    });
    setGridType();
    observer.observe(projectInfoContainerRef.current!);
  }, []);

  useEffect(() => {
    let tempInfomation;
    if (type === 'business') {
      tempInfomation = businessInfomation;
    } else {
      tempInfomation = showBuilding ? buildingInfomation : industryInfomation;
    }

    tempInfomation.forEach((item) => {
      showBuilding
        ? setBuildingValue(item.children, buildingInfo)
        : setValue(item.children, projectDetail);
    });

    showBuilding ? getBuildingGridData() : getGridData(tempInfomation);
  }, [projectDetail, showBuilding, buildingInfo, doubleGrid]);

  return (
    <div
      className="project-info-container"
      ref={projectInfoContainerRef}
      onSelect={() => false}
      onCopy={() => false}
    >
      {gridData.map((gridItem: any) => (
        <div
          className={`project-info-block ${doubleGrid ? 'double-grid' : 'single-grid'}`}
          key={gridItem.title}
        >
          <div className="info-title">
            <div>{gridItem.title}</div>
            {gridItem.title === '基本信息' ? (
              <Tooltip
                placement="right"
                overlayStyle={{ maxWidth: '600' }}
                title="办公面积和项目等级取该项目最近季度数据进行展示"
              >
                <JLLSvg
                  icon="info"
                  size={20}
                  className="info-icon"
                />
              </Tooltip>
            ) : null}
          </div>
          {doubleGrid ? (
            <div className="info-grid-row">
              {gridItem.children.map((oneGridItem: any, idx: number) => (
                <div
                  className="info-grid"
                  key={idx}
                >
                  {oneGridItem?.map &&
                    oneGridItem?.map((item: any) => (
                      <React.Fragment key={item.label}>
                        <div>
                          {item.label}
                          {item.label === '开发商类型' ? <DevTypeDialog /> : null}
                        </div>
                        <div>{item.value}</div>
                      </React.Fragment>
                    ))}
                </div>
              ))}
            </div>
          ) : (
            <div className="info-grid">
              {gridItem.children.map((item: any, i: number) => (
                <React.Fragment key={i}>
                  <div>
                    {item.label}
                    {item.label === '开发商类型' ? <DevTypeDialog /> : null}
                  </div>
                  <div>{item.value}</div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GridInfo;
