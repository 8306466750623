import {
  getCompetitionGroups,
  queryCityDateRange,
  queryCityPickerOptionsAction,
  queryDistrictAndSubMarketOptions,
  queryDistrictSubmarkets,
  queryIndustryOptionsAction,
  set,
} from '.';

export const queryBlockTradePageOptions = async () => {
  await Promise.all([
    queryCityPickerOptionsAction(),
    // getter.queryDistrictAndSubMarketOptions!(cityName),
  ]);
  set((state) => {
    state.loading.blockTrade = false;
  });
};
export const queryIndustryPageOptions = async (cityName: string) => {
  await Promise.all([
    queryIndustryOptionsAction(),
    queryCityPickerOptionsAction(),
    queryDistrictAndSubMarketOptions(cityName),
    queryCityDateRange(cityName),
  ]);

  set((state) => {
    state.loading.industry = false;
  });
};

// 数据分析—城市变化
export const queryDataAnalysisPageOptions = async (cityNameList: string[]) => {
  await Promise.all([
    getCompetitionGroups(),
    queryIndustryOptionsAction(),
    queryCityPickerOptionsAction(),
    queryDistrictSubmarkets(cityNameList),
  ]);
  set((state) => {
    state.loading.dataAnalysis = false;
  });
};

export const queryReportPageOptions = async () => {
  await queryCityPickerOptionsAction();
  set((state) => {
    state.loading.report = false;
  });
};
