import { Position } from 'jlld/es/JLLMap/types';
import { useMapContext } from '@uiw/react-amap-map';
import { useEffect } from 'react';
import { calculateArrowVertices, containerToPostion } from './method';

export interface BezierPathProps {
  center: Position;
  others: Position[];
  reverse?: boolean;
}

interface ParamMap {
  start: Position;
  end: Position;
  control: Position;
}

const BezierPath = (props: BezierPathProps) => {
  const { map, container } = useMapContext();
  const { center, others } = props;
  useEffect(() => {
    if (center && others && map) {
      const centerR = 24;
      const otherR = 24;
      const paramsMapList: ParamMap[] = [];

      let quadrantPosition = 1;
      let centerPixel: Position;
      let othersPixels: Position[];
      //  start为中心点，X,Y 变量名没说是经纬度就都是像素值
      let startX = 0;
      let startY = 0;
      let endX = 0;
      let endY = 0;
      let startPointLngLat: Position = [0, 0];
      let controlDistance = 0;
      let controlX = 0;
      let controlY = 0;
      // bezier进度 0 - 1
      const t = 0.5;
      // 先初始化箭头，后面直接setPath就可以了
      const polylineInstanceList: AMap.Polygon[] = others.map(() => {
        const line = new AMap.Polygon({
          fillColor: '#DE0614',
          strokeOpacity: 1,
          fillOpacity: 1,
          strokeColor: '#DE0614',
          strokeWeight: 1,
          zIndex: 51,
        });

        map!.add(line);
        return line;
      });

      const bezierInstanceList: AMap.BezierCurve[] = others.map(() => {
        const bezier = new AMap.BezierCurve({
          strokeWeight: 2, //线条宽度
          strokeColor: '#3A3E43', //线条颜色
          strokeOpacity: 1,
        });

        map!.add(bezier);
        return bezier;
      });
      // 绘制在bezier曲线上的箭头
      const drawArrow = () => {
        othersPixels.forEach((_, index) => {
          const line = polylineInstanceList[index];
          const { start, control, end } = paramsMapList[index];
          if (Math.abs(start[0] - end[0]) < 16 && Math.abs(start[1] - end[1]) < 16) {
            line.setPath([]);
          } else {
            // 获取当前贝塞尔曲线点位以及切线角度
            const [a, b, c] = calculateArrowVertices(start, control, end, t, props.reverse);
            // 设置新的路径
            line.setPath([
              containerToPostion(map!, a),
              containerToPostion(map!, b),
              containerToPostion(map!, c),
            ]);
          }
        });
      };
      const caculate = () => {
        const centerLngLatdata = map!.lngLatToContainer(center)!;
        centerPixel = [centerLngLatdata.getX!(), centerLngLatdata.getY!()];
        const [centerX, centerY] = centerPixel;
        othersPixels = others.map((other) => {
          const pixel = map!.lngLatToContainer(other)!;
          return [pixel.getX!(), pixel.getY!()];
        });
        // 绘制bezier曲线
        const paths = othersPixels.map((otherPixel, index) => {
          const [otherX, otherY] = otherPixel;
          // 判断象限
          if (centerX < otherX && centerY < otherY) {
            quadrantPosition = 1;
          } else if (centerX > otherX && centerY < otherY) {
            quadrantPosition = 2;
          } else if (centerX > otherX && centerY > otherY) {
            quadrantPosition = 3;
          } else if (centerX < otherX && centerY > otherY) {
            quadrantPosition = 4;
          }
          // 两点间距离
          const distance = Math.sqrt(Math.pow(centerX - otherX, 2) + Math.pow(centerY - otherY, 2));
          // 多一个象限加90度
          const offset = (quadrantPosition - 1) * (Math.PI / 2);
          const h = distance / 8;
          // 角度 命名参考云效文档 https://thoughts.aliyun.com/workspaces/623a8f782f9c95001af6f486/docs/668f44384c60500001b48d54
          const alfa = Math.atan(h / (distance / 2));
          let theta = 0;
          if (quadrantPosition === 2 || quadrantPosition === 4) {
            theta = Math.abs(Math.asin((otherX - centerX) / distance));
          } else {
            theta = Math.abs(Math.asin(Math.abs(centerY - otherY) / distance));
          }
          controlDistance = h / Math.sin(alfa);
          // 使用极坐标, 转直角坐标
          startX = centerR * Math.cos(alfa + theta + offset) + centerX;
          startY = centerR * Math.sin(alfa + theta + offset) + centerY;
          controlX = controlDistance * Math.cos(alfa + theta + offset) + centerX;
          controlY = controlDistance * Math.sin(alfa + theta + offset) + centerY;
          const l = otherR * Math.cos(alfa);
          const k = otherR * Math.sin(alfa);
          const beta = Math.atan(k / (distance - l));
          const AE = (distance - l) / Math.cos(beta);
          // 这里注意，坐标偏移值因为计算式按中心点为0,0计算，所以这里需要加上中心点坐标，而不是加上other的坐标值
          endX = AE * Math.cos(beta + theta + offset) + centerX;
          endY = AE * Math.sin(beta + theta + offset) + centerY;

          startPointLngLat = containerToPostion(map!, [startX, startY]);
          const controlPointLngLat = containerToPostion(map!, [controlX, controlY]);
          const endPointLngLat = containerToPostion(map!, [endX, endY]);
          // 记住这些值给绘制箭头用
          paramsMapList[index] = {
            start: [startX, startY],
            end: [endX, endY],
            control: [controlX, controlY],
          };
          return [startPointLngLat, [controlPointLngLat, endPointLngLat]];
        }) as [Position, Position[]][];
        // 绘制一次箭头
        drawArrow();
        bezierInstanceList.forEach((item, index) => {
          item.setPath(paths[index] as any);
        });
      };

      caculate();
      window.addEventListener('resize', caculate);
      map!.on('zoomchange', caculate);
      map!.on('mapmove', caculate);
      const resizeObserver = new ResizeObserver(caculate);
      resizeObserver.observe(container!);
      // 箭头绘制动画
      // const drawAnimate = () => {
      //   t += 0.002;
      //   if (t > 1) {
      //     t = 0;
      //   }
      //   drawArrow();

      //   requestAnimationFrame(drawAnimate);
      // };

      // drawAnimate();

      return () => {
        if (map) {
          try {
            polylineInstanceList.forEach((item) => {
              map.remove(item);
            });
            bezierInstanceList.forEach((item) => {
              map.remove(item);
            });
          } catch (e) {
            console.log(e);
          }
          window.removeEventListener('resize', caculate);
          resizeObserver.unobserve(container!);
          map.off('zoomchange', caculate);
          map!.off('mapmove', caculate);
        }
      };
    }
  }, [center, others]);
  return null;
};

export default BezierPath;
